import React from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import teamStyle from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.jsx";

class AboutUsSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div style={{ paddingBottom: 0 }} className={classes.section}>
        <h2 className={classes.title} style={{ paddingBottom: 15 }}>
          Who we are
        </h2>
        <h3 className={classes.title} style={{ paddingBottom: 15 }}>
          Welcome to Amedia Networks Limited
        </h3>
        <h5 style={{ paddingBottom: 10 }} className={classes.description}>
          Founded in 2005 and headquartered in Nicosia, Cyprus, Amedia Networks
          Limited has grown into a trusted name in the media distribution
          industry. For nearly two decades, we have been connecting the world to
          premium entertainment. Our mission is to bridge the gap between
          creators and audiences, delivering stories that resonate globally.
        </h5>
        <h4 className={classes.title} style={{ paddingBottom: 15 }}>
          A Vast Library of Entertainment
        </h4>
        <h5 style={{ paddingBottom: 10 }} className={classes.description}>
          We hold a vast library of titles that spans an impressive range of
          genres and formats. From critically acclaimed and award-winning movies
          to binge-worthy TV series, captivating miniseries, groundbreaking
          anime, and beloved classics, our collection caters to diverse tastes
          and audiences. Whether it’s independent cinema, blockbuster hits,
          thought-provoking documentaries, family-friendly content, or trending
          series, we provide access to entertainment that speaks to everyone.
        </h5>
        <h4 className={classes.title} style={{ paddingBottom: 15 }}>
          A Trusted Partner in Global Media
        </h4>
        <h5 style={{ paddingBottom: 10 }} className={classes.description}>
          We hold licensing and distribution agreements with some of the world’s
          most renowned studios, ensuring that our catalogue represents the best
          in entertainment. Our partnerships include major names like:
          <h3>AMC</h3> The network behind some of the most iconic TV shows.
          <h3>FIFTH SEASON</h3> A 21st-century global entertainment powerhouse.
          <h3>Fremantle</h3>A global leader in entertainment and storytelling.
          <h3>Production I.G</h3>
          Pioneers in anime and Japanese storytelling.
          <h3>About Premium Content</h3>
          Specialists in premium scripted programming. We also continue to
          create relationships with new partners worldwide, expanding our reach
          across the USA, UK, France, Germany, Japan, and Canada. These
          partnerships ensure our library remains diverse, relevant, and
          unparalleled in quality.
          <h4 className={classes.title} style={{ paddingBottom: 15 }}>
            Our Vision
          </h4>
          Amedia Networks Limited is more than just a distributor; we are
          passionate about storytelling. We aim to connect creators and
          audiences, providing a platform for authentic voices and remarkable
          narratives to thrive in an ever-evolving entertainment landscape.
          Explore our services and discover how we can help you access the best
          content, from critically acclaimed films to the latest trending
          series. Let us connect you with world-class entertainment and help
          your audience experience the very best of global storytelling.
        </h5>
      </div>
    );
  }
}

export default withStyles(teamStyle)(AboutUsSection);
