const hoverCardStyle = {
    cardHover: {
        "&:hover": {
            "& $cardHeaderHover": {
                transform: "translate3d(0, -50px, 0)"
            }
        }
    },
    media: {
        height: 206,
        borderRadius: "6px",
        boxShadow:
            "0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)",

    },
    cardHeaderHover: {
        transition: "all 300ms cubic-bezier(0.34, 1.61, 0.7, 1)"
    },
    cardHoverUnder: {
        position: "absolute",
        zIndex: "1",
        top: "-50px",
        width: "calc(100% - 30px)",
        left: "17px",
        right: "17px",
        textAlign: "center"
    }
};

export default hoverCardStyle;
