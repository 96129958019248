import React from "react";
import { ReactTinyLink } from "react-tiny-link";
// nodejs library that concatenates classes
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import latestNewsStyle from "assets/jss/material-kit-react/views/ecommercePageSections/latestNewsStyle.jsx";

function LatestNewsSection({ classes }) {
  return (
    <div style={{ paddingTop: 10 }} className={classes.section}>
      <h2 className={classes.title} style={{ paddingBottom: 15 }}>
        Latest News
      </h2>
      <h5>
        Stay up to date with the latest developments at Amedia Networks Limited.
        From new partnerships to the acquisition of exciting content, we are
        constantly working to enhance our offerings and grow our global
        presence. Follow this section for regular updates and news on our
        expanding content offerings.
      </h5>
      <div>
        <GridContainer>
          <GridItem className={classes.topSpace} xs={12} sm={12} md={4}>
            <ReactTinyLink
              header="Upcoming A24 Movie Releases"
              description="The indie distributor has a promising line-up of new films, from Celine Song's sophomore feature 'Materialists' to Kyle Mooney’s debut 'Y2K.'"
              cardSize="large"
              showGraphic={false}
              maxLine={8}
              minLine={1}
              url="https://ew.com/upcoming-a24-movie-releases-8678399"
            />
          </GridItem>
          <GridItem className={classes.topSpace} xs={12} sm={12} md={4}>
            <ReactTinyLink
              header="Upcoming A24 TV Shows"
              description="The studio's television slate features original series and adaptations of best-selling books."
              cardSize="large"
              showGraphic={false}
              maxLine={8}
              minLine={1}
              url="https://ew.com/every-upcoming-a24-tv-show-8692252"
            />
          </GridItem>
          <GridItem className={classes.topSpace} xs={12} sm={12} md={4}>
            <ReactTinyLink
              header="AMC Networks Streaming Subscribers in North America"
              description="Number of subscribers to AMC Networks' streaming services from 2020 to 3rd quarter 2024"
              cardSize="large"
              showGraphic={false}
              maxLine={8}
              minLine={1}
              url="https://www.statista.com/statistics/1054642/amc-networks-streaming-subscriber-north-america"
            />
          </GridItem>
          <GridItem className={classes.topSpace} xs={12} sm={12} md={4}>
            <ReactTinyLink
              header="Killing Line Digital Debut"
              description="Kodansha kicks off the new year with the reveal of January’s new digital manga series debut Killing Line by Satoshi Morie, the creator of the dark romantic thriller Searching for My Perfect Brother."
              cardSize="large"
              showGraphic={false}
              maxLine={8}
              minLine={1}
              url="https://kodansha.us/2025/01/07/killing-line-digital-debut-january/"
            />
          </GridItem>
          <GridItem className={classes.topSpace} xs={12} sm={12} md={4}>
            <ReactTinyLink
              header="Peacock's Gladiator Series Announcement"
              description="Peacock has given a straight-to-series order to a gladiator drama series based on Daniel Mannix’s nonfiction book “Those About to Die.”"
              cardSize="large"
              showGraphic={false}
              maxLine={8}
              minLine={1}
              url="https://variety.com/2022/tv/news/peacock-gladiator-series-those-about-to-die-roland-emmerich-1235326095/"
            />
          </GridItem>
          <GridItem className={classes.topSpace} xs={12} sm={12} md={4}>
            <ReactTinyLink
              header="Toonami Original Anime: Rick and Morty, Uzumaki, and More"
              description="The road to Uzumaki was over two decades in the making"
              cardSize="large"
              showGraphic={false}
              maxLine={8}
              minLine={1}
              url="https://www.polygon.com/anime/446097/toonami-original-anime-rick-morty-uzumaki-history"
            />
          </GridItem>
 
          <GridItem className={classes.topSpace} xs={12} sm={12} md={4}>
            <ReactTinyLink
              header="About Premium Content Inside Content"
              description="In this episode of Inside Content, I am joined by Emmanuelle Guilbart, co-founder of About Premium Content (APC), a Paris-based boutique distributor that specialises in the development, production, financing, and international distribution of high-end TV programs."
              cardSize="large"
              showGraphic={false}
              maxLine={10}
              minLine={1}
              url="https://www.3vision.tv/news-insights/about-premium-content-inside-content"
            />
          </GridItem>
          <GridItem className={classes.topSpace} xs={12} sm={12} md={4}>
            <ReactTinyLink
              header="App Quality: Before and After the Video Player"
              description="The streaming video app has become prominent in our lives. Whether it’s Netflix, HBO Max, Disney+, Prime Video or some other video service, the video app is essential for a professional and high-quality viewing experience."
              cardSize="large"
              showGraphic={false}
              maxLine={8}
              minLine={1}
              url="https://npaw.com/blog/app-quality-before-after-the-video-player/"
            />
          </GridItem>
          <GridItem className={classes.topSpace} xs={12} sm={12} md={4}>
            <ReactTinyLink
              header="Fifth Season Promotes Christopher Slager To Head Of Film"
              description="Fifth Season has upped Christopher Slager to Head of Film as the studio expands its in-house production and development of feature films."
              cardSize="large"
              showGraphic={false}
              maxLine={8}
              minLine={1}
              url="https://deadline.com/2024/03/fifth-season-christopher-slager-1235869981/#recipient_hashed=a5f59c6216e83b264e21d98b0a2b2559d27911c30daf76118caa764808cc0717&recipient_salt=235089a65fa859a7e984f3e227179f5183110becc9f2a41ae7b97507e75040c9"
            />
          </GridItem>
          <GridItem className={classes.topSpace} xs={12} sm={12} md={4}>
            <ReactTinyLink
              header="BOAT ROCKER Completes Purchase of Outstanding Minority Stake of Insight Productions Ltd"
              description="Boat Rocker Media Inc. ('Boat Rocker') (TSX: BRMI), an independent, integrated global entertainment company, today announced that it has completed its purchase of the outstanding minority stake of Insight Productions Ltd. ('Insight Productions')"
              cardSize="large"
              showGraphic={false}
              maxLine={8}
              minLine={1}
              url="https://www.boatrocker.com/news/press-release-details/2024/BOAT-ROCKER-COMPLETES-PURCHASE-OF-OUTSTANDING-MINORITY-STAKE-OF-INSIGHT-PRODUCTIONS-LTD/default.aspx"
            />
          </GridItem>
          <GridItem className={classes.topSpace} xs={12} sm={12} md={4}>
            <ReactTinyLink
              header="Paris 2024: France Télévisions Brings a Live Production with Epic Sustainability Credentials to Its Coverage of the Olympic Flames Journey"
              description="France Télévisions has opted for what could be viewed as a risky production and transmission strategy for its coverage of the Olympic flame through France for its new digital channel, france.tv Paris 2024."
              cardSize="large"
              showGraphic={false}
              maxLine={8}
              minLine={1}
              url="https://www.svgeurope.org/blog/headlines/paris-2024-france-televisions-brings-a-live-production-with-epic-sustainability-credentials-to-its-coverage-of-the-olympic-flames-journey"
            />
          </GridItem>
          <GridItem className={classes.topSpace} xs={12} sm={12} md={4}>
            <ReactTinyLink
              header="Sinestra and Fremantle to Adapt Andrew O'Hagan’s Caledonian Road for TV"
              description="Fremantle and Sinestra, the production outfit from Emmy award-winning Director Johan Renck (Chernobyl) and producer Michael Parets (Spaceman), today announce a new television adaptation, Caledonian Road; a biting portrait of modern British class, politics, and money told through five interconnected families and their rising―and declining―fortunes."
              cardSize="large"
              showGraphic={false}
              maxLine={8}
              minLine={1}
              url="https://fremantle.com/news/sinestra-and-fremantle-to-adapt-andrew-o-hagan-s-caledonian-road-for-tv"
            />
          </GridItem>
          <GridItem className={classes.topSpace} xs={12} sm={12} md={4}>
            <ReactTinyLink
              header="A New Era of Media: The Reinvention of News Delivery and Consumption"
              description="The future of news and the definition of “newsroom” can and should change to match the technology of our times. Updating our understanding of these concepts will greatly improve how we reach citizens of our world, increase their level of knowledge, and perhaps shape future dialogue more constructively."
              cardSize="large"
              showGraphic={false}
              maxLine={8}
              minLine={1}
              url="https://www.forbes.com/councils/forbescommunicationscouncil/2023/12/12/a-new-era-of-media-the-reinvention-of-news-delivery-and-consumption/"
            />
          </GridItem>
          <GridItem className={classes.topSpace} xs={12} sm={12} md={4}>
            <ReactTinyLink
              header="Herbert G. Kloiber, Constantin Film, High-End Productions"
              description="Herbert G. Kloiber, who was formerly the owner of German TV and film giant Tele München Group (now known as Leonine), and Constantin Film have set up Vienna-based production company High End Productions, which will produce premium European fiction series for the international market."
              cardSize="large"
              showGraphic={false}
              maxLine={8}
              minLine={1}
              url="https://variety.com/2021/tv/global/herbert-g-kloiber-constantin-film-high-end-productions-1235011742/"
            />
          </GridItem>
          <GridItem className={classes.topSpace} xs={12} sm={12} md={4}>
            <ReactTinyLink
              header="LibraryPass and Kodansha Announce Digital Access for Libraries and Schools"
              description="LibraryPass, Inc. has announced a distribution agreement with Kodansha USA Publishing, a subsidiary of Japan’s largest publishing company, to make its digital catalog of manga available to libraries and schools through Comics Plus."
              cardSize="large"
              showGraphic={false}
              maxLine={8}
              minLine={1}
              url="https://librarypass.com/2021/06/24/librarypass-and-kodansha-announce-digital-access-for-libraries-and-schools-expanding-manga-availability-in-comics-plus"
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

export default withStyles(latestNewsStyle)(LatestNewsSection);
