import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import teamStyle from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.jsx";

import CardHeader from "components/Card/CardHeader.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Danger from 'components/Typography/Danger';

import {matchesRegex} from "helpers/regexTests";

import _ from 'lodash';

class ContactSection extends React.Component {
    constructor(props) {
        super(props);
        // we use this to make the card to appear after the page has been rendered
        this.state = {
            cardAnimaton: "cardHidden",
            errors: {},
            passwordVisible: false,
            submitted: false
        };
    }

    handleChange = name => e => {
        this.setState({[name]: e.target.value})
    };

    onSubmit = async () => {
        const {email, query, name, telephone} = this.state;

        let errors = {};
        if (_.isEmpty(email) || !matchesRegex(email, "email")) {
            errors.email = "Please enter a valid email"
        }

        if (_.isEmpty(query)) {
            errors.query = "Please enter a query"
        }

        if (_.isEmpty(name)) {
            errors.name = "Please enter a name"
        }
        let params = {
            "to": "info@amedia-networks.com.cy",
            "from": "noreply@amedia-networks.com.cy",
            query,
            name,
            email,
            telephone,
            subject: "Query from Amedia website"
        };

        if (_.isEmpty(errors)) {
            fetch("https://api.amedia-networks.com.cy/email", {
                method: 'post',
                body: JSON.stringify(params)
            }).then(
                    (res) => {
                        this.setState(
                            {
                            errors: {},
                            email: "",
                            query: "",
                            name: "",
                            telephone: "",
                            submitted: true
                        });
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            submitted:false,
                            errors: {submit: error.toString()}
                        });
                    }
                )
        } else {

            this.setState({errors: errors}, function () {
                console.log(this.state.errors)
            })
        }
    };

    render() {
        const {classes} = this.props;
  
        return (
            <div className={classes.section}>
                <div>
                    <GridContainer direction="row"
                                   justify="center"
                                   alignItems="stretch">
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                        <h3>
                                    We’d love to hear from you! Whether you're interested in learning more about our content catalogue, discussing a potential partnership, or need assistance, our team is here to help.
                                    
                                    </h3>
                                    <h3 className={classes.description}> For any other inquiries or support, please feel free to reach out via the contact form below and our team will get back to you as soon as possible.
                                    </h3>
                  
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={5}>
                            <Card className={classes[this.state.cardAnimaton]}>
                                <form className={classes.form}>
                                    <CardHeader color="black" className={classes.cardHeader}>
                                        <h4 style={{
                                            color: "white",
                                            margin: "1.75rem 0 0.875rem",
                                            textDecoration: "none",
                                            fontWeight: "700",
                                            fontFamily: `"intro-bold-caps","Roboto Slab", "Times New Roman", serif`,
                                            marginBottom: "1rem",
                                            marginTop: "30px",
                                            minHeight: "32px"
                                        }}>Get In Touch</h4>
                                          
                                    </CardHeader>
                                    <CardBody>
                                        <CustomInput
                                            labelText="Name..."
                                            id="name"
                                            formControlProps={{
                                                fullWidth: true,
                                                error: !_.isEmpty(this.state.errors.name)
                                            }}
                                            inputProps={{
                                                type: "email",
                                                endAdornment: (
                                                    <Icon className={classes.inputIconsColor}>
                                                        {"person"}
                                                    </Icon>
                                                ),
                                                onChange: this.handleChange("name"),
                                                value:this.state.name
                                            }}
                                            helperText={this.state.errors.name}
                                        />
                                        <CustomInput
                                            labelText="Email..."
                                            id="email"
                                            formControlProps={{
                                                fullWidth: true,
                                                error: !_.isEmpty(this.state.errors.email)
                                            }}
                                            inputProps={{
                                                type: "email",
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Email className={classes.inputIconsColor}/>
                                                    </InputAdornment>
                                                ),
                                                onChange: this.handleChange("email"),
                                                value:this.state.email
                                            }}
                                            helperText={this.state.errors.email}
                                        />
                                        <CustomInput
                                            labelText="Telephone..."
                                            id="telephone"
                                            formControlProps={{
                                                fullWidth: true,
                                                error: !_.isEmpty(this.state.errors.telephone)
                                            }}
                                            inputProps={{
                                                type: "text",
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Icon className={classes.inputIconsColor}>
                                                            {"phone"}
                                                        </Icon>
                                                    </InputAdornment>
                                                ),
                                                onChange: this.handleChange("telephone"),
                                                value:this.state.telephone
                                            }}
                                            helperText={this.state.errors.telephone}
                                        />
                                        <CustomInput
                                            labelText="Query..."
                                            id="query"
                                            formControlProps={{
                                                fullWidth: true,
                                                error: !_.isEmpty(this.state.errors.name)
                                            }}
                                            inputProps={{
                                                type: "text",
                                                endAdornment: (
                                                    <Icon className={classes.inputIconsColor}>
                                                        {"speaker_notes"}
                                                    </Icon>
                                                ),
                                                onChange: this.handleChange("query"),
                                                value:this.state.query
                                            }}
                                            helperText={this.state.errors.query}
                                        />

                                    </CardBody>
                                    <CardFooter className={classes.cardFooter}>
                                        {this.state.errors.submit && <Danger>{this.state.errors.submit}</Danger>}
                                        {this.state.submitted && <h4>Submitted Successfully</h4>}
                                        <Button style={{marginLeft: "auto", padding: 0}} simple color="primary"
                                                size="lg" onClick={this.onSubmit}>
                                            <Icon className={classes.inputIconsColor} style={{fontSize: 50}}>
                                                {"send"}
                                            </Icon>
                                        </Button>
                                    </CardFooter>
                                </form>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} lg={5}>
                            <Card className={classes[this.state.cardAnimaton]} style={{minHeight: 518}}>
                                <CardHeader color="black" className={classes.cardHeader}>
                                    <h4 style={{
                                        color: "white",
                                        margin: "1.75rem 0 0.875rem",
                                        textDecoration: "none",
                                        fontWeight: "700",
                                        fontFamily: `"intro-bold-caps","Roboto Slab", "Times New Roman", serif`,
                                        marginBottom: "1rem",
                                        marginTop: "30px",
                                        minHeight: "32px"
                                    }}>Address</h4>
                                </CardHeader>
                                <CardBody>
                                    <h4 style={{color: "#3C4858"}}><Icon className={classes.inputIconsColor}>
                                        {"location_on"}
                                    </Icon>
                                    </h4>
                                    <h5 style={{color: "#3C4858", paddingTop: "10%"}}>
                                        Amedia Networks LTD<br/>
                                        19, Polyneikis Street<br/>
                                        Floor 1<br/>
                                        Strovolos, Nicosia<br/>
                                        P.O 2047<br/>
                                        Cyprus<br/>
                                        info@amedia-networks.com.cy<br/>
                                        Social Media <Button
                                        href="https://www.linkedin.com/company/amedia-networks"
                                        target="_blank"
                                        justIcon
                                        color="transparent"
                                        className={classes.margin5}
                                    >
                                        <i className={classes.socials + " fab fa-linkedin"}/>
                                    </Button>
                                    </h5>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        );
    }
}

export default withStyles(teamStyle)(ContactSection);
