import LandingPage from "views/LandingPage/LandingPage.jsx";
import ContactUs from "views/ContactUs/ContactUs.jsx";
import NewsPage from "views/News/NewsPage";
import ServicesPage from "views/ServicesPage/ServicesPage";
import PartnersPage from "../views/PartnersPage/PartnersPage";
import PrivacyPage from "../views/PrivacyPage/PrivacyPage";

var indexRoutes = [
  { path: "/contactus", name: "Contact Us", component: ContactUs },
  { path: "/news", name: "News", component: NewsPage },
  { path: "/services", name: "Services", component: ServicesPage },
  { path: "/partners", name: "Partners", component: PartnersPage },
  { path: "/privacy", name: "Privacy Policy", component: PrivacyPage },
  { path: "/", name: "LandingPage", component: LandingPage },
];

export default indexRoutes;
