import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

class PrivacySection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div
        id="services"
        style={{ paddingBottom: 0 }}
        className={classes.section}
      >
        <h2 className={classes.title} style={{ paddingBottom: 15 }}>
          Privacy Policy
        </h2>
        <h5 style={{ paddingBottom: 10 }} className={classes.description}>
          At Amedia Networks Limited, we value your privacy and are committed to
          protecting your personal data. This Privacy Policy explains how we
          collect, use, store, and protect your information when you visit our
          website or engage with our services.
        </h5>
        <h3 className={classes.title} style={{ paddingBottom: 15 }}>
          1. Information We Collect
        </h3>
        <h5 className={classes.description}>
          We collect various types of information, including:
          <div className={classes.bold}>Personal Information</div>
          When you contact us or submit an inquiry, we may collect personal
          information such as your name, email address, phone number, and
          company details
          <br />
          <div className={classes.bold}>Non Personal Information</div> We may
          also collect non-personal information, such as your IP address,
          browser type, and website usage data, through cookies and similar
          technologies..
        </h5>
        <h3 className={classes.title} style={{ paddingBottom: 15 }}>
          2. How We Use Your Information
        </h3>
        <h5 className={classes.description}>
          The information we collect is used to:
          <h4> Respond to inquiries and provide customer support.</h4>
          <h4> Improve our website and services. </h4>
          <h4>
            Send you newsletters, updates, and promotional material (if you have
            consented to receive them).
          </h4>
          <h4>
            Comply with legal obligations and protect our business interests.
          </h4>
        </h5>
        <h3 className={classes.title} style={{ paddingBottom: 15 }}>
          3. Cookies
        </h3>
        <h5 className={classes.description}>
          Our website does not collect cookies nor gather any data regarding how
          visitors interact with our site.
        </h5>
        <h3 className={classes.title} style={{ paddingBottom: 15 }}>
          4. Data Security
        </h3>
        <h5 className={classes.description}>
          We implement reasonable technical and organizational measures to
          protect your personal data from unauthorized access, loss, or misuse.
          However, please note that no method of data transmission or storage is
          completely secure, and we cannot guarantee absolute protection.
        </h5>
        <h3 className={classes.title} style={{ paddingBottom: 15 }}>
          5. Sharing of Information
        </h3>
        <h5 className={classes.description}>
          We do not sell or rent your personal information to third parties.
          However, we may share your information with trusted service providers,
          partners, or affiliates who assist in our business operations. These
          third parties are obligated to protect your information in accordance
          with this Privacy Policy.
        </h5>
        <h3 className={classes.title} style={{ paddingBottom: 15 }}>
          6. Your Rights
        </h3>
        <h5 className={classes.description}>
          Depending on your location, you may have the following rights
          regarding your personal data:
          <h4>The right to access, correct, or delete your personal data.</h4>
          <h4>The right to object to the processing of your personal data.</h4>
          <h4>
            The right to withdraw your consent for the processing of your data
            at any time.
          </h4>
          To exercise these rights, please contact us using the contact details
          provided below.
        </h5>
        <h3 className={classes.title} style={{ paddingBottom: 15 }}>
          7. Changes to This Privacy Policy
        </h3>
        <h5 className={classes.description}>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or legal requirements. Any updates will be posted on
          this page, and the effective date will be updated accordingly.
        </h5>
        <h3 className={classes.title} style={{ paddingBottom: 15 }}>
          8. Contact Us
        </h3>
        <h5 className={classes.description}>
          If you have any questions or concerns about this Privacy Policy, or if
          you wish to exercise your data protection rights, please contact us{" "}
          <a href="/contactus">here</a>
        </h5>
      </div>
    );
  }
}

export default withStyles(productStyle)(PrivacySection);
