import { container } from "assets/jss/material-kit-react.jsx";

const componentsStyle = theme => ({
  container,
  small:{
    maxWidth:30,
    borderRadius: 100,
    marginLeft: 5,
    marginTop: -2
  },
  brand: {
    color: "white",
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
        color: 'white',
    },
  },
  parallax:{
    backgroundPosition:"100% center"
  },
  title: {
    fontSize: "4.2rem",
    fontWeight: "600",
    display: "inline-block",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.2rem",
      marginTop: 20
    },
    [theme.breakpoints.down("sm")]: {
        fontSize: "2.2rem",
    },
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px 0 0",
    [theme.breakpoints.down("xs")]: {
        marginTop: 120
    },
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    [theme.breakpoints.down("sm")]: {
        margin: "-30px 10px 0px",
    },
  },
  link: {
    textDecoration: "none"
  },
  textCenter: {
    textAlign: "center"
  },
  position: {
    [theme.breakpoints.down("xs")]: {
        backgroundPosition: "100% center"
    },
  },
    footer: {
        background: "radial-gradient(ellipse at center,#585858 0,#232323 100%)",
        backgroundSize: "550% 450%"
    }
});

export default componentsStyle;
