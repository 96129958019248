import { title } from "assets/jss/material-kit-react.jsx";

const productStyle = {
  section: {
    padding: "70px 0",
    textAlign: "center",
  },
  thick: {
    fontWeight: "bold",
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  socialIcons: {
    position: "relative",
    fontSize: "20px !important",
    marginRight: "4px"
  },
  description: {
    color: "#999",
  },
  bullet: {
    paddingLeft: 0,
    listStylePosition: "inside",
  },
  bold: {
    fontWeight: "bold",
  }
};

export default productStyle;
