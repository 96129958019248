import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

class PartnersSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div
        id="services"
        style={{ paddingBottom: 0 }}
        className={classes.section}
      >
        <h2 className={classes.title} style={{ paddingBottom: 15 }}>
          Our Partners
        </h2>
        <h5 style={{ paddingBottom: 10 }} className={classes.description}>
          At Amedia Networks Limited, our strength lies in the strategic
          partnerships we have built with some of the most prestigious names in
          the media industry. These collaborations enable us to offer a diverse
          and premium content library, ensuring our clients have access to
          world-class entertainment.
        </h5>
        <h3 className={classes.title} style={{ paddingBottom: 15 }}>
          Our Esteemed Partners
        </h3>
        <h4 className={classes.title} style={{ paddingBottom: 15 }}>
          A24{" "}
          <a href="https://a24films.com/" target="_blank"  rel="noopener noreferrer">
            <i className={classes.socialIcons + " fa-solid fa-link"} />
          </a>
        </h4>
        <h5 style={{ paddingBottom: 10 }} className={classes.description}>
          Known for producing critically acclaimed and groundbreaking films.
        </h5>
        <h4 className={classes.title} style={{ paddingBottom: 15 }}>
          AMC{" "}
          <a href="https://www.amc.com" target="_blank" rel="noopener noreferrer">
            <i className={classes.socialIcons + " fa-solid fa-link"} />
          </a>
        </h4>
        <h5 style={{ paddingBottom: 10 }} className={classes.description}>
        A leader in creating iconic television series that have captivated audiences globally.
        </h5>
     <h4 className={classes.title} style={{ paddingBottom: 15 }}>
          FIFTH SEASON{" "}
          <a href="https://fifthseason.com" target="_blank" rel="noopener noreferrer">
            <i className={classes.socialIcons + " fa-solid fa-link"} />
          </a>
        </h4>
        <h5 style={{ paddingBottom: 10 }} className={classes.description}>
        A 21st-century global entertainment powerhouse in film and television production.

        </h5>
        <h4 className={classes.title} style={{ paddingBottom: 15 }}>
        France Télévisions{" "}
          <a href="https://www.francetelevisions.fr" target="_blank " rel="noopener noreferrer">
            <i className={classes.socialIcons + " fa-solid fa-link"} />
          </a>
        </h4>
        <h5 style={{ paddingBottom: 10 }} className={classes.description}>
        A cornerstone of French media excellence, delivering high-quality European programming.

        </h5>
        <h4 className={classes.title} style={{ paddingBottom: 15 }}>
        Fremantle{" "}
          <a href="https://www.fremantle.com" target="_blank" rel="noopener noreferrer">
            <i className={classes.socialIcons + " fa-solid fa-link"} />
          </a>
        </h4>
        <h5 style={{ paddingBottom: 10 }} className={classes.description}>
        A worldwide leader in the production and distribution of entertainment formats and storytelling.

        </h5>
        <h4 className={classes.title} style={{ paddingBottom: 15 }}>
        Production I.G{" "}
          <a href="https://www.production-ig.com" target="_blank" rel="noopener noreferrer">
            <i className={classes.socialIcons + " fa-solid fa-link"} />
          </a>
        </h4>
        <h5 style={{ paddingBottom: 10 }} className={classes.description}>
        Renowned for its innovative contributions to the anime industry, bringing Japanese storytelling to the world.
        </h5>
        <h4 className={classes.title} style={{ paddingBottom: 15 }}>
        About Premium Content{" "}
          <a href="https://aboutpremiumcontent.com" target="_blank" rel="noopener noreferrer">
            <i className={classes.socialIcons + " fa-solid fa-link"} />
          </a>
        </h4>
        <h5 style={{ paddingBottom: 10 }} className={classes.description}>
        Specialists in premium scripted programming with global appeal.
        </h5>
        <h4 className={classes.title} style={{ paddingBottom: 15 }}>
        Kodansha {" "}
          <a href="https://www.kodansha.co.jp" target="_blank" rel="noopener noreferrer">
            <i className={classes.socialIcons + " fa-solid fa-link"} />
          </a>
        </h4>
        <h5 style={{ paddingBottom: 10 }} className={classes.description}>
        A giant in the world of anime and manga, inspiring audiences across generations.
        </h5>
        <h3 className={classes.title} style={{ paddingBottom: 15 }}>
        Expanding Our Network
        </h3>
        <h5 style={{ paddingBottom: 10 }} className={classes.description}>
        Our partnerships don’t stop here. We continue to create relationships with new partners worldwide, including in key markets such as the USA, UK, France, Germany, Japan, and Canada. These ongoing collaborations ensure that our content library remains diverse, dynamic, and reflective of global entertainment trends.
<br/><br/>By working with the best in the industry, Amedia Networks Limited remains at the forefront of delivering premium entertainment to audiences around the world.

        </h5>
      </div>
    );
  }
}

export default withStyles(productStyle)(PartnersSection);
