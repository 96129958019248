import { tooltip, cardTitle, title } from "assets/jss/material-kit-react.jsx";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.jsx";
import hoverCardStyle from "assets/jss/material-kit-react/hoverCardStyle.jsx";

const latestNewsStyle = {
    ...hoverCardStyle,
    tooltip,
    section: {
        paddingTop: "70px",
        paddingBottom: "140px",
        textAlign: "center"
    },
    topSpace:{
      marginTop:10,
        marginBottom:10
    },
    title: {
        ...title,
        marginBottom: "1rem",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none",
        textAlign: "centre",
        fontWeight: 300,
        fontFamily: "intro-bold-caps"
    },
    ...imagesStyle,
    itemGrid: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    cardTitle: {
        ...cardTitle,
        marginTop: "0px",
        marginBottom: "3px"
    },
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    cardProductTitle: {
        color: "#3C4858",
        margin: "1.75rem 0 0.875rem",
        textDecoration: "none",
        fontWeight: "700",
        marginTop: "0px",
        marginBottom: "3px",
        textAlign: "left"
    },
    cardProductTitleColor: {
        color: "#3C4858"
    },
    cardCategory: {
        color: "#999999",
        fontSize: "14px",
        paddingTop: "10px",
        marginBottom: "0",
        marginTop: "0",
        margin: "0"
    },
    cardProductDesciprion: {
        textAlign: "center",
        color: "#999999"
    },
    smallTitle: {
        color: "#6c757d"
    },
    description: {
        color: "#999"
    },
    justifyCenter: {
        justifyContent: "center !important"
    },
    socials: {
        marginTop: "0",
        width: "100%",
        transform: "none",
        left: "0",
        top: "0",
        height: "100%",
        lineHeight: "41px",
        fontSize: "20px",
        color: "#999"
    },
    margin5: {
        margin: "5px"
    }
};

export default latestNewsStyle;
