import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

class ServiceSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div
        id="services"
        style={{ paddingBottom: 0 }}
        className={classes.section}
      >
        <h2 className={classes.title} style={{ paddingBottom: 15 }}>
          Our Services
        </h2>
        <h5 style={{ paddingBottom: 10 }} className={classes.description}>
          At Amedia Networks Limited, we pride ourselves on delivering
          comprehensive and innovative content distribution solutions. Our
          services are designed to meet the needs of an ever-evolving
          entertainment industry, ensuring our partners and clients can
          effectively reach global audiences. Here’s how we do it:
        </h5>
        <h3 className={classes.title} style={{ paddingBottom: 15 }}>
          Why Choose US
        </h3>
        <h5 className={classes.description}>
          With nearly two decades of experience, Amedia Networks Limited has
          established itself as a trusted partner in the entertainment industry.
          We combine deep industry expertise with an unwavering commitment to
          quality, innovation, and client satisfaction. Let us help you bring
          your content to the world.
        </h5>
        <h3 className={classes.title} style={{ paddingBottom: 15 }}>
          What We Offer
        </h3>
        <h4 className={classes.title} style={{ paddingBottom: 15 }}>
          Content Licensing
        </h4>
        <h5 style={{ paddingBottom: 10 }} className={classes.description}>
          Our licensing agreements ensure that premium content reaches the right
          audiences, providing unparalleled value to our clients. We acquire and
          license a diverse array of media content including:
        </h5>
        <h6 className={classes.thick}>
          Movies - From blockbusters to indie gems.
        </h6>
        <h6 className={classes.thick}>
          TV Shows - Iconic series and trending hits.
        </h6>
        <h6 className={classes.thick}>
          Miniseries - Engaging stories told in shorter formats.
        </h6>
        <h6 className={classes.thick}>
          Anime - The best of Japanese storytelling.{" "}
        </h6>

        <h4 className={classes.title} style={{ paddingBottom: 15 }}>
          Global Distribution
        </h4>
        <h5 style={{ paddingBottom: 10 }} className={classes.description}>
          Our wide-reaching distribution ensures that creators and rights
          holders can access markets worldwide, delivering their stories to
          where they’re needed most. With a robust global network, we distribute
          content to audiences from:
          <ul className={classes.bullet}>
            <li>USA</li>
            <li>UK</li>
            <li>France</li>
            <li>Germany</li>
            <li>Japan</li>
            <li>Canada</li>
          </ul>
        </h5>
        <h4 className={classes.title} style={{ paddingBottom: 15 }}>
          Tailored Solutions
        </h4>
        <h5 className={classes.title} style={{ paddingBottom: 10 }}>
          Localized Content
        </h5>
        <h5 className={classes.description}>
          Customized language options - including subtitling and dubbing
          <br />
          Cultural adaptations - ensure content resonate with regional audiences
        </h5>
        <h5 className={classes.title} style={{ paddingBottom: 10 }}>
          Platform-Specific Delivery{" "}
        </h5>
        <h5 className={classes.description}>
          Seamless integration with streaming services, traditional
          broadcasters, and physical media platforms. <br />
          Flexible content formatting to meet platform requirements.
        </h5>
        <h5 className={classes.title} style={{ paddingBottom: 10 }}>
          Strategic Collaboration{" "}
        </h5>
        <h5 className={classes.description}>
          We work hand-in-hand with clients to design distribution strategies
          tailored to specific goals and target demographics.
        </h5>
        <h4 className={classes.title} style={{ paddingBottom: 15 }}>
          Content Catalogue Management
        </h4>
        <h5 className={classes.description}>
          Our extensive library is expertly managed to ensure:
        </h5>
        <ul className={classes.bullet}>
          <li>Easy access to the right titles.</li>
          <li>Efficient curation to meet client needs.</li>
          <li>Continuous updates with the latest in entertainment.</li>
        </ul>
      </div>
    );
  }
}

export default withStyles(productStyle)(ServiceSection);
